import React from 'react'
import PropTypes from 'prop-types'
import './_modal.scss'

class ModalComponent extends React.Component {
  constructor (props) {
    super(props)
    if (props.isOpen) {
      document.body.style.overflow = 'hidden'
    }
    this.state = {
      mounted: false,
    }
  }

  componentDidMount () {
    this.setState({ mounted: true });
  }

  render () {
    if (!this.props.isOpen) return null;

    let contentClassName = 'modal-content ';

    if (this.props.contentClassName) {
      contentClassName += this.props.contentClassName;
    }
    
    return (
      <div className='modal-overlay'>
        <div className={this.state.mounted ? `${contentClassName} slide-in` : contentClassName} ref={this.setContentRef}>
          {this.props.children}
        </div>
      </div>
    )
  }
}

ModalComponent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  contentClassName: PropTypes.string
}

export default ModalComponent
