import React from 'react';
import queryString from 'query-string';

import StepThreeView from './three.view';

class StepThreeComponent extends React.Component {
  constructor(props) {
    super(props);
    const query = queryString.parse(this.props.location.search);
    this.lang = query.lang || 'en';
  }

  render() {
    return (
      <StepThreeView lang={this.lang} search={this.props.location.search}/>
    )
  }
}

export default StepThreeComponent;
