/**
 * Button Component
 *
 * Props:
 * id: id to be assigned to the element
 * inputType: submit / reset / button(default)
 * btnStyleType: primary/ secondary / tertiary / sticky / primary-small / secondary-small
 * className: 'addition css classes if any'
 * onHandleBtnClick: function
 * onFocus: function
 * onBlur: function
 * isEnabled: bool
 * isLoading: bool
 * value: string (Text displayed on button)
 */

import React from 'react'
import PropTypes from 'prop-types'
import './_button.scss'

const Button = (props) => {
  let classes = props.classNames ? props.classNames + ' btn ' : ' btn '
  switch (props.btnStyleType) {
    case 'secondary':
      classes += 'secondary '
      break

    case 'tertiary':
      classes += 'tertiary '
      break

    case 'primary':
    default:
      classes += 'primary '
      break
  }
  if (props.className) {
    classes += props.className
  }
  if (!props.isEnabled) {
    classes += 'btn-disabled '
  }

  return (
    <>

      <button
        id={props.id || ''}
        className={classes}
        onClick={props.onHandleBtnClick}
        type={props.inputType || 'button'}
        onFocus={props.onFocus || null}
        onBlur={props.onBlur || null}
        disabled={!props.isEnabled}
      >
        <span className={`button-text ${props.hasIcon ? 'has-icon' : ''}`}>
          {props.value}
        </span>
        {props.hasIcon &&
          <>
            {(props.btnStyleType && props.btnStyleType.startsWith('primary')) &&
              <span className='icon icon-right-arrow' />}
            {(props.btnStyleType && props.btnStyleType.startsWith('secondary')) &&
              <span className='icon icon-right-arrow-orange' />}
            {(props.btnStyleType && props.btnStyleType.startsWith('tertiary') &&
              props.tertiaryIconClasses) &&
                <span className={props.tertiaryIconClasses} />}
          </>}
      </button>
    </>
  )
}

Button.propTypes = {
  className: PropTypes.string,
  btnStyleType: PropTypes.oneOf(['primary', 'secondary', 'tertiary']).isRequired,
  inputType: PropTypes.oneOf(['submit', 'reset', 'button']),
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.string.isRequired,
  isEnabled: PropTypes.bool.isRequired,
  hasIcon: PropTypes.bool,
  tertiaryIconClasses: PropTypes.string
}

export default Button
