import React from 'react';

import { ReactComponent as HomeWorkSVG } from '../../styles/images/homework.svg';
import { ReactComponent as HomeWorkCompletedSVG } from '../../styles/images/homeWorkCompleted.svg';

import Tooltip from '../tooltip';   
import './_homeWork.scss';

const tooltipContent = {
    '1': {
        'en': 'Once you complete the homework, tap on \'Mark as done\'',
        'hi': 'एक बार जब आप होमवर्क पूरा कर लेते हैं, तो \'Mark as done\' पर टैप करें',
        'mr': 'एकदा आपण गृहपाठ पूर्ण केल्यास, \'Mark as done\' वर टॅप करा',
        'ta': 'வீட்டுப்பாடம் முடிந்ததும், \'Mark as done\' என்பதைத் தட்டவும்',
        'te': 'మీరు హోంవర్క్ పూర్తి చేసిన తర్వాత, \'Mark as done\' నొక్కండి',
        'kn': 'ನೀವು ಮನೆಕೆಲಸವನ್ನು ಪೂರ್ಣಗೊಳಿಸಿದ ನಂತರ, \'Mark as done\' ಟ್ಯಾಪ್ ಮಾಡಿ',
        'gu': 'એકવાર તમે હોમવર્ક પૂર્ણ કરી લો, પછી \'Mark as done\' પર ટેપ કરો'
    },
}

const HomeWork = (props) => {
    const { active, lang, complete }  = props;

    return (
        <div style={{ flex: '1 0 0'}} className={active || complete  ? 'active' : ''}>
            <div className='p-relative homework'>
                {complete
                    ? <HomeWorkCompletedSVG />
                    : <HomeWorkSVG/>
                }
                {!complete && <div className='clickable' onClick={() => props.handleClick()}></div>}
                {active ? <Tooltip content={tooltipContent['1'][lang]}/> : ''}
            </div>
        </div>
    );
}

export default HomeWork;
