import React from 'react';
import { withRouter } from 'react-router-dom';

import './_tiles.scss';
import { ReactComponent as SaTile } from '../../styles/images/sa-tile.svg';
import profile from '../../styles/images/profile.png';
import homeQuiz from '../../styles/images/homeQuiz.png';
import askDoubts from '../../styles/images/askDoubts.png';
import homeworkTile from '../../styles/images/homeworkTile.png';
import assessment from '../../styles/images/assessment.png';
import attendance from '../../styles/images/attendance.png';
import unitProgress from '../../styles/images/unitProgress.png';
import media from '../../styles/images/media.png';
import timetable from '../../styles/images/timetable.png';
import Overlay from '../overlay';
import Tooltip from '../tooltip';   

const tooltipContent = {
  '1': {
    'en': 'Click here to attend your classes, daily homework and quizzes',
    'hi': 'अपनी कक्षाओं, दैनिक होमवर्क और क्विज़ में भाग लेने के लिए यहाँ क्लिक करें',
    'mr': 'आपल्या वर्ग, दररोज गृहपाठ आणि क्विझमध्ये उपस्थित राहण्यासाठी येथे क्लिक करा',
    'ta': 'உங்கள் வகுப்புகள், தினசரி வீட்டுப்பாடம் மற்றும் வினாடி வினாக்களில் கலந்து கொள்ள இங்கே கிளிக் செய்க',
    'te': 'మీ తరగతులు, రోజువారీ హోంవర్క్ మరియు క్విజ్‌లకు హాజరు కావడానికి ఇక్కడ క్లిక్ చేయండి',
    'kn': 'ನಿಮ್ಮ ತರಗತಿಗಳು, ದೈನಂದಿನ ಮನೆಕೆಲಸ ಮತ್ತು ರಸಪ್ರಶ್ನೆಗಳಿಗೆ ಹಾಜರಾಗಲು ಇಲ್ಲಿ ಕ್ಲಿಕ್ ಮಾಡಿ',
    'gu': 'તમારા વર્ગો, દૈનિક હોમવર્ક અને ક્વિઝમાં ભાગ લેવા અહીં ક્લિક કરો'
  }
};

const TilesView = (props) => {
  const redirect = () => {
    props.history.push(`/step/one?lang=${props.lang}`);
  }

  return (
    <div>
        <div className='tiles-container'>
          <div className='profile'>
            <img src={profile} alt='profile' />
          </div>
          <div className='content-box'>
            <div className='row'>
              <div className='card active p-relative'>
                <SaTile className='d-full' onClick={() => redirect()}/>
                <Tooltip content={tooltipContent['1'][props.lang]}/>
              </div>
              <div className='card'>
                <img src={homeQuiz} alt='Home Quiz' className='d-full'/>
              </div>
              <div className='card'>
                <img src={askDoubts} alt='Ask Doubts' className='d-full'/>
              </div>
            </div>
            <div className='row'>
              <div className='card'>
                <img src={homeworkTile} alt='Homework' className='d-full'/>
              </div>
              <div className='card'>
                <img src={assessment} alt='Assessment' className='d-full'/>
              </div>
              <div className='card'>
                <img src={attendance} alt='Attendance' className='d-full'/>
              </div>
            </div>
            <div className='row'>
              <div className='card'>
                <img src={unitProgress} alt='Unit Progress' className='d-full'/>
              </div>
              <div className='card'>
                <img src={media} alt='Media' className='d-full'/>
              </div>
              <div className='card'>
                <img src={timetable} alt='Timetable' className='d-full'/>
              </div>
            </div>
          </div>
          <Overlay />
        </div>
    </div>
  )
}

export default withRouter(TilesView);
