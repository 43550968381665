import React from 'react';
import {
  Switch,
  Route,
  Redirect,
  withRouter
} from 'react-router-dom';

import HomeLazyComponent from './components/home';
import ParentWalkthroughComponent from './components/parentWalkthrough';
import StepLazyComponent from './components/steps';
import TourFinishLazyComponent from './components/tourFinish';
import TourSkipLazyComponent from './components/tourSkip';
import TilesLazyComponent from './components/tiles';

class App extends React.Component {
  render() {
    return (
      <Switch>
        <Route exact path="/home" component={HomeLazyComponent} />
        <Route exact path="/parent-walkthrough" component={ParentWalkthroughComponent} />
        <Route exact path="/tour-finish" component={TourFinishLazyComponent} />
        <Route exact path="/tour-skip" component={TourSkipLazyComponent} />
        <Route path="/step" component={StepLazyComponent} />
        <Route path="/tiles" component={TilesLazyComponent} />
        <Route render={() => (<Redirect to={{ pathname: "/home", search: this.props.location.search }} />)} />
      </Switch>
    );
  }
}

export default withRouter(App);
