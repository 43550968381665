import React from 'react';

import './_goals.scss';
import { ReactComponent as Goal1 } from '../../styles/images/goal-1.svg';
import { ReactComponent as Goal2 } from '../../styles/images/goal-2.svg';
import { ReactComponent as Goal3 } from '../../styles/images/goal-3.svg';
import { ReactComponent as Tag } from '../../styles/images/tag.svg';

import Tooltip from '../tooltip';   

const tooltipContent = {
    '1': {
        'en': 'Click here to attend your daily classes',
        'hi': 'अपनी दैनिक कक्षाओं में भाग लेने के लिए यहाँ क्लिक करें',
        'mr': 'आपल्या दैनंदिन वर्गात सहभागी होण्यासाठी येथे क्लिक करा',
        'ta': 'உங்கள் தினசரி வகுப்புகளில் கலந்து கொள்ள இங்கே கிளிக் செய்க',
        'te': 'మీ రోజువారీ తరగతులకు హాజరు కావడానికి ఇక్కడ క్లిక్ చేయండి',
        'kn': 'ನಿಮ್ಮ ದೈನಂದಿನ ತರಗತಿಗಳಿಗೆ ಹಾಜರಾಗಲು ಇಲ್ಲಿ ಕ್ಲಿಕ್ ಮಾಡಿ',
        'gu': 'તમારા દૈનિક વર્ગોમાં ભાગ લેવા અહીં ક્લિક કરો'
    },
    '2': {
        'en': 'Click to complete your homework for the day',
        'hi': 'दिन के लिए अपना होमवर्क पूरा करने के लिए क्लिक करें',
        'mr': 'दिवसासाठी आपले गृहपाठ पूर्ण करण्यासाठी क्लिक करा',
        'ta': 'நாள் உங்கள் வீட்டுப்பாடத்தை முடிக்க கிளிக் செய்க',
        'te': 'రోజు మీ ఇంటి పనిని పూర్తి చేయడానికి క్లిక్ చేయండి',
        'kn': 'ದಿನಕ್ಕಾಗಿ ನಿಮ್ಮ ಮನೆಕೆಲಸವನ್ನು ಪೂರ್ಣಗೊಳಿಸಲು ಕ್ಲಿಕ್ ಮಾಡಿ',
        'gu': 'દિવસ માટે તમારું હોમવર્ક પૂર્ણ કરવા ક્લિક કરો'
    }, 
    '3': {
        'en': 'Click here to attempt quizzes assigned to you',
        'hi': 'आपको सौंपे गए क्विज़ के प्रयास के लिए यहाँ क्लिक करें',
        'mr': 'आपल्याला नियुक्त केलेल्या क्विझचा प्रयत्न करण्यासाठी येथे क्लिक करा',
        'ta': 'உங்களுக்கு ஒதுக்கப்பட்ட வினாடி வினாக்களை முயற்சிக்க இங்கே கிளிக் செய்க',
        'te': 'మీకు కేటాయించిన క్విజ్‌లను ప్రయత్నించడానికి ఇక్కడ క్లిక్ చేయండి',
        'kn': 'ನಿಮಗೆ ನಿಯೋಜಿಸಲಾದ ರಸಪ್ರಶ್ನೆಗಳನ್ನು ಪ್ರಯತ್ನಿಸಲು ಇಲ್ಲಿ ಕ್ಲಿಕ್ ಮಾಡಿ', 
        'gu': 'તમને સોંપેલ ક્વિઝનો પ્રયાસ કરવા અહીં ક્લિક કરો'
    }
}

const Goals = (props) => {
    const { active, lang }  = props;
    
    return (
        <div className='goal-container'>
            <div className={`goal ${active === 1 ? 'active' : ''}`}>
                <div>
                    <Goal1 onClick={() => props.handleClick()}/>
                    {active === 1 ? <Tooltip content={tooltipContent['1'][lang]}/> : ''}
                    <Tag className='tag'/>
                </div>
            </div>
            <div className={`goal ${active === 2 ? 'active' : ''}`}>
                <div>
                    <Goal2 onClick={() => props.handleClick()}/>
                    {active === 2 ? <Tooltip content={tooltipContent['2'][lang]} top={true}/> : ''}
                    <Tag className='tag'/>
                </div>
            </div>
            <div className={`goal ${active === 3 ? 'active' : ''}`}>
                <div>
                    <Goal3 onClick={() => props.handleClick()}/>
                    {active === 3 ? <Tooltip content={tooltipContent['3'][lang]} top={true}/> : ''}
                    <Tag className='tag' />
                </div>
            </div>
        </div>
    )
}

export default Goals;
