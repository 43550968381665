import React from 'react';

import { ReactComponent as LiveClassSVG } from '../../styles/images/liveClass.svg';

import Tooltip from '../tooltip';   

const tooltipContent = {
    '1': {
        'en': 'Click on the play button to attend class',
        'hi': 'क्लास अटेंड करने के लिए प्ले बटन पर क्लिक करें',
        'mr': 'वर्गात जाण्यासाठी प्ले बटणावर क्लिक करा',
        'ta': 'வகுப்பில் கலந்து கொள்ள பிளே பொத்தானைக் கிளிக் செய்க',
        'te': 'తరగతికి హాజరు కావడానికి ప్లే బటన్ పై క్లిక్ చేయండి',
        'kn': 'ತರಗತಿಗೆ ಹಾಜರಾಗಲು ಪ್ಲೇ ಬಟನ್ ಕ್ಲಿಕ್ ಮಾಡಿ',
        'gu': 'વર્ગમાં ભાગ લેવા માટે પ્લે બટન પર ક્લિક કરો',
    },
}

const LiveClass = (props) => {
    const { active, lang }  = props;

    return (
        <div style={{ flex: '1 0 0'}} className={active ? 'active' : ''}>
            <div className='p-relative'>
                <LiveClassSVG onClick={() => props.handleClick()}/>
                {active ? <Tooltip content={tooltipContent['1'][lang]}/> : ''}
            </div>
        </div>
    );
}

export default LiveClass;
