import React from 'react';

import { ReactComponent as LiveClassNavSVG } from '../../styles/images/liveClassNav.svg';

const LiveClassNav = () => {
    return (
        <div>
            <LiveClassNavSVG />
        </div>
    );
}

export default LiveClassNav;
