import React from 'react';

import { ReactComponent as HomeworkNavSVG } from '../../styles/images/homeworkNav.svg';

const HomeworkNav = () => {
    return (
        <div>
            <HomeworkNavSVG />
        </div>
    );
}

export default HomeworkNav;
