import React, { useState, useEffect } from 'react';

import TopNav from '../../topnav';
import ProfileCard from '../../profileCard';
import Goals from '../../goals';
import Overlay from '../../overlay';
import DateScroll from '../../dateScroll';
import Quiz from '../../quiz';
import FinishButton from '../../finish';
import Progress from '../../progress';
import QuizNav from '../../quizNav';

const StepThreeView = (props) => {
  const [active, setActive] = useState({ quiz: true, finish: false }); 
  const [isComplete, setIsComplete] = useState(false); 
  const [page1Class, setpage1] = useState(['page-1']);
  const [page2Class, setpage2] = useState(['page-2']);

  useEffect(() => {
    window.scrollTo(0, document.body.scrollHeight);
  }, []);

  const handleClick = (index) => {
    setpage1([...page1Class, 'slide-out']);
    setpage2([...page2Class, 'slide-in']);
  }

  const quizClick = () => {
    setActive({ quiz: false, finish: true });
    setIsComplete(true);
  }

  return (
    <>
      <div className={page1Class.join(' ')}>
        <TopNav />
        <div className='content-box'>
          <ProfileCard />
          <DateScroll />
          <p style={{ marginTop: 0 }}><b>Goals for the day</b></p>
          <Goals 
            active={3}
            handleClick={handleClick}
            lang={props.lang}
            top={true}
          />
        </div>
        <Overlay />
      </div>
      <div className={page2Class.join(' ')}>
        <QuizNav />
        <Progress complete={isComplete}/>
        <div className='content-box'>
          <Quiz complete={isComplete} active={active.quiz} lang={props.lang} handleClick={quizClick}/>
          <FinishButton 
            index={3}
            active={active.finish}
            next='/step/four'
            lang={props.lang}
            search={props.search}
            points={15}
            />
        </div>
        <Overlay />
      </div>
    </>
  )
}

export default StepThreeView;
