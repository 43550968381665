import React from 'react';

import { ReactComponent as ProgressCompleteSVG } from '../../styles/images/progressComplete.svg';
import { ReactComponent as ProgressInCompleteSVG } from '../../styles/images/progressIncomplete.svg';

import './_progress.scss';

const Progress = (props) => {
    return (
        <div style={{padding: '8px 15px'}}>  
            {props.complete 
                ? <ProgressCompleteSVG />
                : <ProgressInCompleteSVG />
            }
            
        </div>
    );
}

export default Progress;
