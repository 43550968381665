import React from 'react';
import queryString from 'query-string';

import StepFourView from './four.view';

class StepFourComponent extends React.Component {
  constructor(props) {
    super(props);
    const query = queryString.parse(this.props.location.search);
    this.lang = query.lang || 'en';
  }

  render() {
    return (
      <StepFourView lang={this.lang}/>
    )
  }
}

export default StepFourComponent;
