import React, { useState, useEffect } from 'react';

import './_four.scss'
import TopNav from '../../topnav';
import ProfileCard from '../../profileCard';
import Goals from '../../goals';
import Overlay from '../../overlay';
import DateScroll from '../../dateScroll';

const StepFourView = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  const [activeIndex, setActiveIndex] = useState(1);

  const next = () => {
    setActiveIndex(activeIndex + 1);
  }

  const redirect = () => {
    window.history.replaceState({}, '', '/tour-finish');
  }

  return (
    <>
      <div className='page-1'>
        <TopNav lang={props.lang} active={activeIndex} index={5}/>
        <div className='content-box'>
          <ProfileCard lang={props.lang} active={activeIndex}/>
          <DateScroll lang={props.lang} active={activeIndex}/>
          <p style={{ marginTop: 0 }}><b>Goals for the day</b></p>
          <Goals />
        </div>
        <div className='next' >
          {activeIndex < 3 
            ? <span onClick={() => next()}>Next ({activeIndex}/3)</span>
            : <div onClick={() => redirect()}><span> Close </span></div>
          }
        </div>
        <Overlay />
      </div>
    </>
  )
}

export default StepFourView;

