import React from 'react';

import { ReactComponent as DateScrollSVG } from '../../styles/images/dateScroll.svg';

import Tooltip from '../tooltip';   

const tooltipContent = {
    '1': {
        'en': 'Click on the arrow to view old and pending goals',
        'hi': 'पुराने और लंबित लक्ष्यों को देखने के लिए तीर पर क्लिक करें',
        'mr': 'जुनी आणि प्रलंबित ध्येये पाहण्यासाठी बाणावर क्लिक करा',
        'ta': 'பழைய மற்றும் நிலுவையில் உள்ள இலக்குகளைக் காண அம்புக்குறியைக் கிளிக் செய்க',
        'te': 'పాత మరియు పెండింగ్‌లో ఉన్న లక్ష్యాలను వీక్షించడానికి బాణంపై క్లిక్ చేయండి',
        'kn': 'ಹಳೆಯ ಮತ್ತು ಬಾಕಿ ಇರುವ ಗುರಿಗಳನ್ನು ವೀಕ್ಷಿಸಲು ಬಾಣದ ಮೇಲೆ ಕ್ಲಿಕ್ ಮಾಡಿ',
        'gu': 'જૂના અને બાકી લક્ષ્યોને જોવા માટે તીર પર ક્લિક કરો',
    },
}

const DateScroll = (props) => {
    const { active, lang }  = props;
    const isActive = active === 1;

    return (
        <div style={{paddingTop: '10px'}} className={isActive ? 'active' : ''}>
            <div className='p-relative'>
                <DateScrollSVG />
                {isActive ? <Tooltip content={tooltipContent['1'][lang]}/> : ''}
            </div>
        </div>
    );
}

export default DateScroll;
