import React from 'react';
import { Link } from 'react-router-dom';

import './_home.scss';
import Button from '../button';
import { ReactComponent as StartSVG } from '../../styles/images/start.svg';

const HomeView = (props) => {
  return (
    <div>
        <div className='home-container'>
          <div className='svg'>
            <StartSVG style={{width: '100%', height: '100%'}}/>
          </div>
          <div className='content-box'>
            <div className='content'>
              <p className='title'><b>Introducing</b></p>
              <p className='app-name'>LEAD Student App</p>
              <p className='text'>
                Your one stop access to School@Home is ready. Complete the quick tutorial to start using the app.
              </p>
            </div>
            <Link to={{ pathname:'tiles', search: props.search }}>
              <Button btnStyleType='primary' isEnabled={true}
                value='Get Started'
              />
            </Link>
          </div>
        </div>
    </div>
  )
}

export default HomeView;
