import React, { useState } from 'react';

import TopNav from '../../topnav';
import ProfileCard from '../../profileCard';
import Goals from '../../goals';
import Overlay from '../../overlay';
import DateScroll from '../../dateScroll';
import HomeWork from '../../homeWork';
import FinishButton from '../../finish';
import Progress from '../../progress';
import HomeworkNav from '../../homeworkNav';

const StepTwoView = (props) => {
  const [active, setActive] = useState({ homeWork: true, finish: false }); 
  const [isComplete, setIsComplete] = useState(false); 
  const [page1Class, setpage1] = useState(['page-1']);
  const [page2Class, setpage2] = useState(['page-2']);

  const handleClick = (index) => {
    setpage1([...page1Class, 'slide-out']);
    setpage2([...page2Class, 'slide-in']);
  }

  const homeWorkClick = () => {
    setIsComplete(true);
    setActive({ homeWork: false, finish: true });
  }

  return (
    <>
      <div className={page1Class.join(' ')}>
        <TopNav />
        <div className='content-box'>
          <ProfileCard />
          <DateScroll />
          <p style={{ marginTop: 0 }}><b>Goals for the day</b></p>
          <Goals 
            active={2}
            handleClick={handleClick}
            lang={props.lang}
          />
        </div>
        <Overlay />
      </div>
      <div className={page2Class.join(' ')}>
        <HomeworkNav />
        <Progress complete={isComplete}/>
        <div className='content-box'>
          <HomeWork complete={isComplete} active={active.homeWork} lang={props.lang} handleClick={homeWorkClick}/>
          <FinishButton index={2}
            active={active.finish}
            next='/step/three'
            lang={props.lang}
            search={props.search}
            points={10}  
            />
        </div>
        <Overlay />
      </div>
    </>
  )
}

export default StepTwoView;
