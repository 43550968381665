import React from 'react';

import { ReactComponent as TopnavSVG } from '../../styles/images/topnav.svg';
import { ReactComponent as DownloadSVG } from '../../styles/images/download.svg';
import Tooltip from '../tooltip'; 
import './_topnav.scss';  

const tooltipContent = {
    '1': {
        'en': 'Download all Workbooks & timetable from here',
        'hi': 'सभी कार्यपुस्तिकाएँ और समय सारिणी यहाँ से डाउनलोड करें',
        'mr': 'येथून सर्व वर्कबुक आणि वेळापत्रक डाउनलोड करा',
        'ta': 'அனைத்து பணிப்புத்தகங்கள் மற்றும் கால அட்டவணையை இங்கிருந்து பதிவிறக்கவும்',
        'te': 'అన్ని వర్క్‌బుక్‌లు & టైమ్‌టేబుల్‌ను ఇక్కడ నుండి డౌన్‌లోడ్ చేసుకోండి',
        'kn': 'ಎಲ್ಲಾ ಕಾರ್ಯಪುಸ್ತಕಗಳು ಮತ್ತು ವೇಳಾಪಟ್ಟಿಯನ್ನು ಇಲ್ಲಿಂದ ಡೌನ್‌ಲೋಡ್ ಮಾಡಿ',
        'gu': 'બધા વર્કબુક અને સમયપત્રક અહીંથી ડાઉનલોડ કરો'
    },
}

const TopNav = (props) => {
    const { active, lang }  = props;
    const isActive = active === 3;

    return (
        <div>
            <div className='p-relative nav'>
                <TopnavSVG />
                <div className={isActive ? 'download active' : 'd-none'}>
                    <DownloadSVG />
                </div>
                {isActive ? <Tooltip content={tooltipContent['1'][lang]}/> : ''}
            </div>
        </div>
    );

}

export default TopNav;