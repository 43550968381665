import React from 'react';

import { ReactComponent as QuizSVG } from '../../styles/images/quiz.svg';
import { ReactComponent as QuizCompletedSVG } from '../../styles/images/quizCompleted.svg';
import './_quiz.scss';
import Tooltip from '../tooltip';

const tooltipContent = {
    '1': {
        'en': 'Click on \'Start Now\' to attempt the quiz',
        'hi': 'क्विज़ का प्रयास करने के लिए \'Start Now\' पर क्लिक करें',
        'mr': 'क्विझचा प्रयत्न करण्यासाठी Now \'Start Now\' वर क्लिक करा',
        'ta': 'வினாடி வினாவை முயற்சிக்க \'Start Now\' என்பதைக் கிளிக் செய்க',
        'te': 'క్విజ్‌ను ప్రయత్నించడానికి \'Start Now\' పై క్లిక్ చేయండి',
        'kn': 'ರಸಪ್ರಶ್ನೆ ಪ್ರಯತ್ನಿಸಲು \'Start Now\' ಕ್ಲಿಕ್ ಮಾಡಿ',
        'gu': 'ક્વિઝને અજમાવવા \'Start Now\' પર ક્લિક કરો',
    },
}
const Quiz = (props) => {
    const { active, lang, complete }  = props;

    return (
        <div style={{ flex: '1 0 0'}} className={active ? 'active' : ''}>
            <div className='p-relative'>
                {complete
                    ? <QuizCompletedSVG />
                    : <QuizSVG onClick={() => props.handleClick()}/>
                }
                {active ? <Tooltip content={tooltipContent['1'][lang]}/> : ''}
            </div>
        </div>
    );
}

export default Quiz;
