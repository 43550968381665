import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import StepOneLazyComponent from './one';
import StepTwoLazyComponent from './two';
import StepThreeLazyComponent from './three';
import StepFourLazyComponent from './four';

const routesArr = [{
  path: 'one',
  component: StepOneLazyComponent,
}, {
  path: 'two',
  component: StepTwoLazyComponent,
}, {
  path: 'three',
  component: StepThreeLazyComponent,
}, {
  path: 'four',
  component: StepFourLazyComponent,
}]

class StepsComponent extends React.Component {
  constructor(props) {
    super(props)
    this.url = props.match.url
  }

  render() {
    return (
      <>
        <Switch>
          {
            routesArr.map((route, i) => {
              let path = `${this.url}/${route.path}`
              return (
                <Route exact key={i} path={path} render={(props) => <route.component {...props} />}
                />
              )
            })
          }
          <Route render={() => (<Redirect to="/home" />)}/>
        </Switch>
      </>
    )
  }
}

export default StepsComponent
