import React from 'react';

import HomeView from './home.view';

class HomeComponent extends React.Component {
  render() {
    return (
      <HomeView search={this.props.location.search}/>
    )
  }
}

export default HomeComponent
