import React from 'react';
import queryString from 'query-string';

import TilesView from './tiles.view';

class TileComponent extends React.Component {
  constructor(props) {
    super(props);
    const query = queryString.parse(this.props.location.search);
    this.lang = query.lang || 'en';
  }

  render() {
    return (
      <TilesView search={this.props.location.search} lang={this.lang}/>
    )
  }
}

export default TileComponent
