import React from 'react'

import './_tooltip.scss'

const Tooltip = (props) => {
    return (
        <div className={`tooltip ${props.top ? 'top' : 'bottom'}`}>
            <span>{props.content}</span>
        </div>
    );
}

export default Tooltip;