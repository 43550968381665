import React from 'react';

import ParentWalkthroughView from './parentWalkthrough.view';

class ParentWalkthroughComponent extends React.Component {
  render() {
    return (
      <ParentWalkthroughView search={this.props.location.search} />
    )
  }
}

export default ParentWalkthroughComponent
