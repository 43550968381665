import React from 'react';

import { ReactComponent as QuizNavSVG } from '../../styles/images/quiznav.svg';

const QuizNav = () => {
    return (
        <div>
            <QuizNavSVG />
        </div>
    );
}

export default QuizNav;
