import React from 'react';

import { ReactComponent as ProfileSVG } from '../../styles/images/profileCard.svg';
import Tooltip from '../tooltip';   

const tooltipContent = {
    '1': {
        'en': 'Keep a tab on all the medals & points earned',
        'hi': 'अर्जित सभी पदकों और बिंदुओं पर एक टैब रखें',
        'mr': 'मिळवलेल्या सर्व पदकांवर आणि गुणांवर टॅब ठेवा',
        'ta': 'சம்பாதித்த அனைத்து பதக்கங்கள் மற்றும் புள்ளிகளில் ஒரு தாவலை வைத்திருங்கள்',
        'te': 'సంపాదించిన అన్ని పతకాలు & పాయింట్లపై ట్యాబ్ ఉంచండి',
        'kn': 'ಗಳಿಸಿದ ಎಲ್ಲಾ ಪದಕಗಳು ಮತ್ತು ಅಂಕಗಳ ಬಗ್ಗೆ ಟ್ಯಾಬ್ ಇರಿಸಿ',
        'gu': 'પ્રાપ્ત કરેલા બધા મેડલ અને પોઇન્ટ્સ પર એક ટેબ રાખો',
    },
}

const ProfileCard = (props) => {
    const { active, lang }  = props;
    const isActive = active === 2;

    return (
        <div className={isActive ? 'active' : ''}>
            <div className='p-relative'>
                <ProfileSVG />
                {isActive ? <Tooltip content={tooltipContent['1'][lang]}/> : ''}
            </div>
        </div>
    );
}

export default ProfileCard;