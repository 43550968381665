import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import Tooltip from '../tooltip';   
import Button from '../button';
import ModalComponent from '../modal'
import './_finish.scss';

const tooltipContent = {
    '1': {
        'en': 'Click on \'Finish\' to earn points',
        'hi': 'अंक अर्जित करने के लिए \'Finish\' पर क्लिक करें',
        'mr': 'गुण मिळविण्यासाठी \'Finish\' वर क्लिक करा',
        'ta': 'புள்ளிகளைப் பெற \'Finish\' என்பதைக் கிளிக் செய்க',
        'te': 'పాయింట్లు సంపాదించడానికి \'Finish\' పై క్లిక్ చేయండి',
        'kn': 'ಅಂಕಗಳನ್ನು ಗಳಿಸಲು \'Finish\' ಕ್ಲಿಕ್ ಮಾಡಿ',
        'gu': 'પોઇન્ટ મેળવવા માટે \'Finish\' પર ક્લિક કરો',
    },
    '2': {
        'en': 'Click here to return to the goals of the day and select the next task',
        'hi': 'दिन के लक्ष्यों पर लौटने और अगले कार्य का चयन करने के लिए यहां क्लिक करें',
        'mr': 'दिवसाची लक्ष्ये परत येण्यासाठी येथे क्लिक करा आणि पुढील कार्य निवडा',
        'ta': 'அன்றைய குறிக்கோள்களுக்குத் திரும்ப இங்கே கிளிக் செய்து அடுத்த பணியைத் தேர்ந்தெடுக்கவும்',
        'te': 'రోజు లక్ష్యాలకు తిరిగి రావడానికి ఇక్కడ క్లిక్ చేయండి మరియు తదుపరి పనిని ఎంచుకోండి',
        'kn': 'ದಿನದ ಗುರಿಗಳಿಗೆ ಮರಳಲು ಇಲ್ಲಿ ಕ್ಲಿಕ್ ಮಾಡಿ ಮತ್ತು ಮುಂದಿನ ಕಾರ್ಯವನ್ನು ಆಯ್ಕೆ ಮಾಡಿ',
        'gu': 'દિવસના લક્ષ્યો પર પાછા ફરવા માટે અહીં ક્લિક કરો અને આગલું કાર્ય પસંદ કરો',
    }
}

const modalText = {
    1: 'You have attended all of your classes. </br> Finish other goals to earn more points.',
    2: 'You have completed all of your homework. </br> Finish other goals to earn more points.',
    3: 'You have completed all goals for the day. </br> Finish goals regularly to earn more points.',
}

const FinishButton = (props) => {
    const [showModal, setShowModal] = useState(false);
    const { active, lang, index, points }  = props;
    let isActive = active;

    const handleClick = () => {
        setShowModal(true);
    }

    return (
        <>
            <div className={isActive && !showModal ? 'active' : ''}>
                <div className='p-relative'>
                    <Button btnStyleType='primary'
                        isEnabled={true}
                        value='Finish'
                        onHandleBtnClick={() => handleClick()}
                    />
                    {isActive && !showModal
                        ? <Tooltip content={tooltipContent['1'][lang]} top={true}/>
                        : ''
                    }
                </div>
            </div>
            {
            showModal
                ? <ModalComponent
                    isOpen={true}
                >
                    <div className='popup-content p-relative'>
                        <p className='title'><b>Congratulations! You have earned</b></p>
                        <p className='points'>{points} Points</p>
                        <p className='content' dangerouslySetInnerHTML={{ __html: modalText[index] }} >
                        </p>
                        <Link to={{ pathname: props.next, search: props.search }} >
                            <Button btnStyleType='primary' isEnabled={true}
                            value='Go Back to Dashboard'
                            />
                        </Link>
                        <Tooltip content={tooltipContent['2'][lang]} />
                    </div>
                </ModalComponent>
                : null
            }
        </>
    );
}

export default FinishButton;
