import React from 'react';

import close from '../../styles/images/close.svg';

const overlay = {
    top: 0,
    left: 0,
    width: '100%',
    bottom: 0,
    position: 'absolute',
    zIndex: 10,
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
};

const closeBtn = {
    width: '24px',
    left: '20px',
    position: 'fixed',
    top: '20px',
    zIndex: '22'
}

const Overlay = (props) => {
    const redirect = () => {
        window.history.replaceState({}, '', '/tour-skip');
    }

    return (
        <>
            <div style={overlay}></div>
            <div onClick={() => redirect()}>
                <img src={close} alt='close' style={closeBtn}></img>
            </div>
        </>
    );
}

export default Overlay;
